import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoadingScreen from '../../LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { updateDoc, doc, deleteDoc, collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore, auth, storage } from '../../../firebase';

const ContactModal = ({ isOpen, onClose, onSubmit, projectData }) => {
  const [website, setWebsite] = useState(projectData.domainUrl || '');
  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let imageUrl = null;
      if (image) {
        const storageRef = ref(storage, `contact_images/${Date.now()}_${image.name}`);
        await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(storageRef);
      }
      await onSubmit({ website, message, imageUrl });
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Contact Support</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            required
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send'}
          </button>
          <button type="button" onClick={onClose} disabled={isSubmitting}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

const Configuration = ({ projectData, projectId, onConfigured, updateProjectData }) => {
  const [status, setStatus] = useState('checking');
  const [error, setError] = useState(null);
  const [missingConfigurations, setMissingConfigurations] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [overallProgress, setOverallProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    checkConfiguration();
  }, [projectData]);

  const checkConfiguration = async () => {
    setStatus('checking');
    setError(null);
    setMissingConfigurations([]);
    setAttempts(0);
    setOverallProgress(0);

    try {
      const [hostnameResponse, certificateResponse, cnameVerified] = await Promise.all([
        axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/checkHostnameStatus', {
          params: { domain: `www.${projectData.domainUrl}` }
        }),
        axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/checkCertificateStatus', {
          params: { domain: `www.${projectData.domainUrl}` }
        }),
        verifyCNAME()
      ]);

      const missing = [];
      let progress = 0;

      if (hostnameResponse.data.status === 'active') progress += 25;
      else missing.push('Hostname not active (This can take a few minutes)');

      if (certificateResponse.data.status === 'active') progress += 25;
      else missing.push('Certificate not active');

      if (cnameVerified) progress += 25;
      else missing.push('CNAME not verified');

      if (projectData.currentCNAME) progress += 25;
      else missing.push('Current CNAME not set');

      setOverallProgress(progress);
      setMissingConfigurations(missing);

      if (missing.length === 0) {
        await checkWebsiteAvailability();
      } else {
        setStatus('incompatible');
      }
    } catch (error) {
      console.error('Error checking configuration:', error);
      setError('An error occurred while checking the configuration. Please try again.');
      setStatus('error');
    }
  };

  const verifyCNAME = async () => {
    try {
      const expectedCNAME = `${projectData.proxyCode}.proxy.linguatabs.com`;
      const response = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/verifyCnameRecord', {
        params: { 
          domain: `www.${projectData.domainUrl}`,
          expectedCNAME
        }
      });
      if (response.data.success) {
        await updateProjectData({ cnameVerified: true });
      }
      return response.data.success;
    } catch (error) {
      console.error('Error verifying CNAME:', error);
      return false;
    }
  };

  const checkWebsiteAvailability = async () => {
    while (attempts < 5) {
      try {
        const response = await axios.get(`https://us-central1-lingua-tabs.cloudfunctions.net/proxyRequest`, {
          params: { url: `https://www.${projectData.domainUrl}` }
        });
        if (response.status !== 404) {
          setStatus('success');
          await updateProjectStatus(true);
          return;
        }
      } catch (error) {
        console.error('Error checking website availability:', error);
      }
      setAttempts(attempts + 1);
      await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds between attempts
    }
    setStatus('websiteUnavailable');
  };

  const updateProjectStatus = async (isComplete) => {
    const currentUser = auth.currentUser;
    if (currentUser && projectId) {
      const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectId);
      const updatedData = { 
        onboardingComplete: isComplete,
        hostnameStatus: true,
        certificateStatus: true,
        cnameVerified: true,
        currentStep: 4
      };
      await updateDoc(projectRef, updatedData);
      updateProjectData(updatedData);
    } else {
      console.error('Unable to update project status: User not authenticated or project ID not provided');
    }
  };

  const handleDeleteProject = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      // Delete the custom hostname from Cloudflare
      const domainUrl = projectData.domainUrl;
      if (domainUrl) {
        await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/deleteHostname', {
          params: { domainUrl }
        });
      }

      // Delete the project from Firestore
      await deleteDoc(doc(firestore, 'customers', currentUser.uid, 'projects', projectId));
      navigate('/projects');
    } catch (error) {
      console.error('Error deleting project:', error);
      setError('Error deleting project');
    }
  };

  const handleContactSubmit = async (formData) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('User not authenticated');
      return;
    }

    try {
      const contactRef = await addDoc(collection(firestore, 'contacts'), {
        userId: currentUser.uid,
        website: formData.website,
        message: formData.message,
        imageUrl: formData.imageUrl,
        createdAt: new Date(),
        projectId: projectId
      });
      console.log('Contact form submitted successfully:', contactRef.id);
    } catch (error) {
      console.error('Error submitting contact form:', error);
      throw error;
    }
  };

  if (status === 'checking' || overallProgress < 100) {
    return (
      <div className="form-container">
        <h2>Finalizing Configuration...</h2>
        <progress value={overallProgress} max="100"></progress>
        <p>{overallProgress}% complete</p>
      </div>
    );
  }

  if (status === 'success' || overallProgress === 100) {
    setTimeout(() => {
      onConfigured();
      navigate(`/project/${projectId}`);
    }, 3000);

    return (
      <div className="form-container">
        <h2>Configuration Complete!</h2>
        <p>Your project has been successfully set up. Redirecting to project page...</p>
      </div>
    );
  }

  if (status === 'incompatible' || status === 'websiteUnavailable') {
    return (
      <div className="form-container">
        <h2>Configuration Issue Detected</h2>
        <p>We've encountered some challenges while setting up your site. Our team is here to help!</p>
        {status === 'incompatible' && (
          <>
            <p>Missing configurations:</p>
            <ul>
              {missingConfigurations.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </>
        )}
        {status === 'websiteUnavailable' && (
          <p>We're having trouble accessing your website after {attempts} attempts. This could be due to various factors such as server configuration or firewall settings.</p>
        )}
        <p>Please contact our support team for assistance in resolving these issues.</p>
        <button onClick={checkConfiguration} className="retry-button">Try Again</button>
        <button onClick={() => setIsContactModalOpen(true)} className="contact-button">Contact Support</button>
        <button onClick={handleDeleteProject} className="delete-button">Delete Project</button>
        <ContactModal 
          isOpen={isContactModalOpen}
          onClose={() => setIsContactModalOpen(false)}
          onSubmit={handleContactSubmit}
          projectData={projectData}
        />
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="error-message">
        <h2>Configuration Error</h2>
        <p>{error}</p>
        <button onClick={checkConfiguration} className="retry-button">Retry</button>
        <button onClick={() => setIsContactModalOpen(true)} className="contact-button">Contact Support</button>
        <button onClick={handleDeleteProject} className="delete-button">Delete Project</button>
        <ContactModal 
          isOpen={isContactModalOpen}
          onClose={() => setIsContactModalOpen(false)}
          onSubmit={handleContactSubmit}
          projectData={projectData}
        />
      </div>
    );
  }

  return null;
};

export default Configuration;