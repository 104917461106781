import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/white.svg';
import SettingsIcon from '../../assets/settings2.svg';
import LogoutIcon from '../../assets/logout.svg';
import AddIcon from '../../assets/add.svg';
import { getAuth, signOut } from 'firebase/auth';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './header.css';

const SideNav = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const auth = getAuth();

  useEffect(() => {
    const q = query(collection(firestore, "customers", auth.currentUser.uid, "projects"), where("projectLocked", "==", true));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const projects = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProjects(projects);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const handleAddProject = () => {
    navigate('/projects/setup', { state: { isNewProject: true } });
  };

  return (
    <div className="side-nav">
      <div className="icon-logo-container">
        <Link to="/" className="logo-link">
          <img src={Logo} alt='demo' className="icon-logo" />
        </Link>
      </div>
      <div className="nav-links">
        {projects && projects.length > 0 && (
          <button 
            className="add-project" 
            onClick={handleAddProject}
            title="Add Project"  // Add this line
          >
            <img src={AddIcon} alt='add icon' className="add-icon" />
          </button>
        )}
        {projects && projects.map((project) => (
          <div 
            key={project.id} 
            className="project-icon" 
            onClick={() => navigate(`/project/${project.id}`, { replace: true })}
            title={project.projectName || project.domainUrl}
          >
            <img 
              src={`https://www.google.com/s2/favicons?sz=64&domain_url=${project.serverType === 'proxy' ? 'www.' : ''}${project.domainUrl}`}
              alt={project.projectName || project.domainUrl}
            />
          </div>
        ))}
      </div>
      <div className="support-logout">
        <Link to="/settings" className="nav-link">
          <img src={SettingsIcon} alt='settings icon' className="nav-icon" />
        </Link>
        <Link className="nav-link" onClick={handleLogout}>
          <img src={LogoutIcon} alt='demo' className="nav-icon" />
        </Link>
      </div>
    </div>
  );
}

export default SideNav;
