import React from 'react';
import './DeleteConfirmationModal.css';

function DeleteConfirmationModal({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to delete this project? This action cannot be undone.</p>
        <div className="modal-actions">
          <button onClick={onConfirm} className="confirm-button">Yes, Delete</button>
          <button onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
