// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getDatabase } from 'firebase/database';
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyBU1eCK5t-sxvYVsUZiOXkAwj1PeveulA0",
  authDomain: "lingua-tabs.firebaseapp.com",
  databaseURL: "https://lingua-tabs-default-rtdb.firebaseio.com",
  projectId: "lingua-tabs",
  storageBucket: "lingua-tabs.appspot.com",
  messagingSenderId: "153816410609",
  appId: "1:153816410609:web:1e6b864e18e1947d43fc50",
  measurementId: "G-K7KYRZLWBV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const db = getDatabase(app);
const storage = getStorage(app);



export { auth, firestore, functions, db, storage };