import React, { useEffect, useRef } from 'react';
import { createNoise3D } from 'simplex-noise';

const Background = () => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const noise3D = createNoise3D();
  const speed = 0.0005;

  const resizeCanvas = (canvas, context) => {
    const res = Math.ceil(window.innerHeight / 120);
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    context.fillStyle = '#000000';
    context.fillRect(0, 0, canvas.width, canvas.height);
    return res;
  };

  const draw = (context, width, height, res, progress) => {
    const w = Math.ceil(width / res);
    const h = Math.ceil(height / res);

    for (let x = 0; x < w; x++) {
      for (let y = 0; y < h; y++) {
        const noiseValue = noise3D((progress + x) / (w * 1.5), (progress + y) / (h * 1.5), progress);
        context.fillStyle = `hsl(${(1360 * Math.abs(noiseValue)) % 360}, 100%, 73%)`;
        context.fillRect(x * res, y * res, res, res);
      }
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let progress = 0;
    let res = resizeCanvas(canvas, context);

    const animate = () => {
      progress += speed;
      draw(context, canvas.width, canvas.height, res, progress);
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      res = resizeCanvas(canvas, context);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default Background;