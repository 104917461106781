import React, { createContext, useContext, useState } from 'react';

const CustomerPlanContext = createContext();

export const CustomerPlanProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userProjects, setUserProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  return (
    <CustomerPlanContext.Provider 
      value={{ 
        user, 
        userProjects, 
        loading, 
        setUser, 
        setUserProjects, 
        setLoading 
      }}
    >
      {children}
    </CustomerPlanContext.Provider>
  );
};

export const useCustomerPlan = () => useContext(CustomerPlanContext);