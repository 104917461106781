import { auth, firestore } from "../firebase";
import { addDoc, collection, onSnapshot, updateDoc, doc, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export const getCheckoutUrl = async (priceId, projectId) => {
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  if (!priceId) throw new Error("Price ID is not provided");

  console.log("Creating checkout session for user:", userId, "with priceId:", priceId, "and projectId:", projectId);

  const checkoutSessionRef = collection(firestore, "customers", userId, "checkout_sessions");
  const docRef = await addDoc(checkoutSessionRef, {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    metadata: {
      projectId, // Include projectId in metadata
    },
  });

  console.log("Checkout session document created:", docRef.id);

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, async (snap) => {
      const data = snap.data();
      console.log("Checkout session snapshot data:", data);
      const { error, url, subscriptionId, current_period_end } = data || {};
      if (error) {
        console.error("Error in checkout session snapshot:", error);
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        console.log("Stripe Checkout URL:", url);

        // Ensure subscriptionId and current_period_end are defined before updating the document
        if (subscriptionId && current_period_end) {
          try {
            const projectRef = doc(firestore, 'customers', userId, 'projects', projectId);
            await updateDoc(projectRef, {
              subscriptionStatus: 'active',
              subscriptionId: subscriptionId,
              subscriptionEnd: current_period_end,
            });
          } catch (updateError) {
            console.error("Error updating project document:", updateError);
          }
        } else {
          console.error("Missing subscriptionId or current_period_end in checkout session data");
        }

        unsubscribe();
        resolve(url);
      }
    });
  });
};


export const getPortalUrl = async () => {
  const user = auth.currentUser;
  let dataWithUrl;
  try {
    const functions = getFunctions(firestore.app, "us-central1");
    const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    dataWithUrl = data;
    console.log("Reroute to Stripe portal: ", dataWithUrl.url);
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};

export const updatePlanIdAfterSubscription = async (newPlanId) => {
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  const userRef = doc(firestore, 'customers', userId);
  await setDoc(userRef, { planId: newPlanId }, { merge: true });
};
