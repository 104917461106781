import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TxtComponent = ({ projectData, onVerified, updateProjectData }) => {
  const [txtName, setTxtName] = useState('');
  const [txtValue, setTxtValue] = useState('');
  const [certificateStatus, setCertificateStatus] = useState('gathering txt data...');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    fetchCertificateValidation();
    const id = setInterval(fetchCertificateValidation, 10000); // Check every 10 seconds
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);

  const fetchCertificateValidation = async () => {
    try {
      const response = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/getCertificateValidation', {
        params: { domain: `www.${projectData.domainUrl}` },
      });
      if (response.data.success) {
        if (response.data.certificateStatus === 'active') {
          setCertificateStatus('Certificate is active');
          await updateProjectData({ certificateStatus: true });
          clearInterval(intervalId);
          onVerified();
        } else {
          setTxtName(response.data.txtName);
          setTxtValue(response.data.txtValue);
          setCertificateStatus('TXT record ready for verification');
        }
      }
    } catch (error) {
      console.error('Error fetching certificate validation:', error);
      setCertificateStatus('Error fetching TXT record information');
    } finally {
      setIsLoadingInitial(false);
    }
  };

  const verifyTxt = async () => {
    setVerificationMessage('');
    setIsVerifying(true);
    try {
      const txtResponse = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/verifyTxtRecord', {
        params: { domain: `www.${projectData.domainUrl}`, expectedName: txtName, expectedValue: txtValue }
      });
      
      if (txtResponse.data.success) {
        setVerificationMessage('TXT record verified successfully! Checking certificate status...');
        await updateProjectData({ txtVerification: true });
        
        const certResponse = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/checkCertificateStatus', {
          params: { domain: `www.${projectData.domainUrl}` },
        });
        
        if (certResponse.data.status === 'active') {
          setCertificateStatus('Certificate is active');
          await updateProjectData({ certificateStatus: true });
          onVerified();
        } else {
          setCertificateStatus('Certificate is being processed. This may take a few minutes.');
        }
      } else {
        setVerificationMessage('TXT record verification failed. Please check your DNS settings and try again.');
      }
    } catch (error) {
      console.error('Error verifying TXT record:', error);
      setVerificationMessage('Error verifying TXT record. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="form-container">
      <h3>Add TXT Record</h3>
      {isLoadingInitial ? (
        <p>Loading TXT record information...</p>
      ) : txtName && txtValue ? (
        <>
          <p>Please add the following TXT record to your domain's DNS settings:</p>
          <table className="cname-table">
            <tbody>
              <tr>
                <td><strong>Name:</strong></td>
                <td>
                  <span 
                    onClick={() => copyToClipboard(txtName)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {txtName}
                  </span>
                  <span> (Click to copy)</span>
                </td>
              </tr>
              <tr>
                <td><strong>Value:</strong></td>
                <td>
                  <span 
                    onClick={() => copyToClipboard(txtValue)}
                    style={{cursor: 'pointer', textDecoration: 'underline'}}
                  >
                    {txtValue}
                  </span>
                  <span> (Click to copy)</span>
                </td>
              </tr>
            </tbody>
          </table>
          <button onClick={verifyTxt} className="form-button" disabled={isVerifying}>
            {isVerifying ? 'Verifying...' : 'Verify TXT Record'}
          </button>
          {verificationMessage && <p>{verificationMessage}</p>}
        </>
      ) : (
        <p>Failed to load TXT record information. Please refresh the page to try again.</p>
      )}
      <p>Certificate Status: {certificateStatus}</p>
      {certificateStatus === 'Certificate is active' && (
        <p>Your SSL certificate is active. You will be moved to the next step shortly...</p>
      )}
    </div>
  );
};

export default TxtComponent;