import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DemoLogo from '../assets/logo.svg';
import ReportBugModal from '../components/ReportBugModal';
import ReferralModal from '../components/ReferralModal';
import './HomePage.css';

const HomePage = () => {
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const [isBugModalOpen, setIsBugModalOpen] = useState(false);
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    const handleMouseMove = (e) => {
      const rect = button.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      button.style.setProperty('--mouse-x', `${x}px`);
      button.style.setProperty('--mouse-y', `${y}px`);
    };

    button.addEventListener('mousemove', handleMouseMove);
    return () => button.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleCreateProject = () => {
    navigate('/projects/setup', { state: { isNewProject: true } });
  };

  return (
    <div className="home-container">
      <div className="home-content">
        <img src={DemoLogo} alt="logo" className="home-logo" />
        <h1>Webflow Translation Beta Now Open!</h1>
        <p>Exciting news! Our beta is now open to all Webflow users. Break down language barriers and expand your global reach with our smooth, accurate translations. Whether you're a startup or an established brand, we've got your multilingual needs covered.</p>
        <button 
          ref={buttonRef} 
          className="dashboard-button"
          onClick={handleCreateProject}
        >
          Start Your Free Plan
        </button>
        <button 
          className="dashboard-button"
          onClick={() => setIsReferralModalOpen(true)}
        >
          Refer a Friend
        </button>
        <p className="referral-info">Each referral gets you one month free after your first paid month!</p>
        <p className="report-bug-link" onClick={() => setIsBugModalOpen(true)}>
          Report a Bug
        </p>
      </div>
      <ReportBugModal isOpen={isBugModalOpen} onClose={() => setIsBugModalOpen(false)} />
      <ReferralModal isOpen={isReferralModalOpen} onClose={() => setIsReferralModalOpen(false)} />
    </div>
  );
}

export default HomePage;
