import React, { useState, useEffect } from 'react';
import { auth, firestore, firebase } from '../firebase';
import {
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithPopup,
  GoogleAuthProvider, // Add this import
  sendEmailVerification
} from 'firebase/auth';
import { doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import './Settings.css';

const ReauthModal = ({ isOpen, onClose, onSubmit, onPasswordChange, currentPassword }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>Verify it's you</h3>
        <p>To ensure it's really you, please enter your current password.</p>
        <input
          type="password"
          value={currentPassword}
          onChange={onPasswordChange}
          placeholder="Current Password"
        />
        <div className="modal-actions">
          <button onClick={onSubmit}>Verify</button>
          <button onClick={onClose} className="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  );
};

const SettingsPage = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isReauthOpen, setIsReauthOpen] = useState(false);
  const [userProvider, setUserProvider] = useState('');
  const [userPlan, setUserPlan] = useState(null); 

  useEffect(() => {
    const fetchProviderData = () => {
      const user = auth.currentUser;
      if (user && user.providerData.length > 0) {
        setUserProvider(user.providerData[0].providerId);
      }
    };
    fetchProviderData();

    const fetchUserPlan = async () => {
      const userRef = doc(firestore, 'customers', auth.currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        setUserPlan(userDoc.data().planId);
      }
    };
  
    fetchProviderData();
    fetchUserPlan();
  }, []);  

  const reauthenticate = async () => {
    try {
      if (userProvider === 'google.com') {
        // Use Google for reauthentication
        const googleProvider = new GoogleAuthProvider();
        await signInWithPopup(auth, googleProvider); // Using signInWithPopup for Google reauthentication
      } else {
        // Use Email/Password for reauthentication
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email, currentPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
      }
    } catch (error) {
      console.error('Reauthentication error:', error);
      setErrorMessage(error.message);
    }
  };  

  const handleChangeEmail = async () => {
    if (!email) {
      setErrorMessage("Please enter a new email.");
      return;
    }
    try {
      await reauthenticate(currentPassword);
      const actionCodeSettings = {
        url: window.location.href, // The URL to redirect to after email is verified.
      };
      await auth.currentUser.verifyBeforeUpdateEmail(email, actionCodeSettings);
      const userRef = doc(firestore, 'customers', auth.currentUser.uid);
      await updateDoc(userRef, {
        'tempEmail': email,
      });
      setEmail('');
      setErrorMessage('');
      alert('Verification email sent. Please check your email to confirm the change.');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleChangePassword = async () => {
    if (!newPassword) {
      setErrorMessage("Please enter a new password.");
      return;
    }
    try {
      await reauthenticate(currentPassword);
      await updatePassword(auth.currentUser, newPassword);
      setNewPassword('');
      setErrorMessage('');
      alert('Password updated successfully!');
      setIsReauthOpen(false);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleDeleteAccount = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    try {
      await reauthenticate(currentPassword);
      await deleteDoc(doc(firestore, 'customers', auth.currentUser.uid));
      await deleteUser(auth.currentUser);
      alert('Account deleted successfully.');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handlePasswordChangeClick = () => {
    setIsReauthOpen(true);
  };

  return (
    <div className="settings-container">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="settings-form">
        {userProvider === 'password' && (
          <>
            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="New Email"
              />
              <button className="login-button" onClick={handleChangeEmail}>Change Email</button>
            </div>
            <div className="form-group">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
              <button className="login-button" onClick={handlePasswordChangeClick}>Change Password</button>
            </div>
          </>
        )}
        <div className="form-group">
          {confirmDelete ? (
            <>
              <p>Are you sure you want to delete your account? This action is irreversible.</p>
              <button onClick={handleDeleteAccount} className="danger">Confirm Delete Account</button>
            </>
          ) : (
            <button onClick={() => setConfirmDelete(true)} className="delete-project-btn-button">Delete Account</button>
          )}
        </div>
      </div>
      <ReauthModal
        isOpen={isReauthOpen}
        onClose={() => setIsReauthOpen(false)}
        onSubmit={handleChangePassword}
        onPasswordChange={(e) => setCurrentPassword(e.target.value)}
        currentPassword={currentPassword}
      />
    </div>
  );
};

export default SettingsPage;
