import React from 'react';
import './Modal.css';
import closeIcon from '../assets/close.svg'; // Adjust the path according to your file structure

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-container">
      <img
          src={closeIcon}
          alt="Close"
          className="language-close-icon"
          onClick={onClose}
      />
        {children}
      </div>
    </div>
  );
};

export default Modal;