// src/components/projects/PaymentStatusPopup.js
import React from 'react';
import './Payment.css';

const PaymentStatusPopup = ({ isOpen, status, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <h1>{status === 'success' ? 'Payment Successful!' : 'Payment Cancelled'}</h1>
        <p>
          {status === 'success' 
            ? 'Your payment was successful! You can now proceed to select your languages.'
            : 'Something went wrong with your payment. You can try again or select the Free plan.'
          }
        </p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PaymentStatusPopup;
