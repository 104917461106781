import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { auth, firestore } from '../../firebase';
import axios from 'axios';
import DeleteConfirmationModal from '../../components/projects/DeleteConfirmationModal';
import LoadingScreen from '../../components/LoadingScreen';
import backIcon from '../../assets/back.svg';

const ProjectSettingsPage = () => {
  const { projectName } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState(null);
  const [newApiKey, setNewApiKey] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setError("User not authenticated");
        return;
      }

      try {
        const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
        const projectDoc = await getDoc(projectRef);

        if (projectDoc.exists()) {
          setProjectData(projectDoc.data());
        } else {
          setError("Project not found");
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError("Error fetching project data");
      }
    };

    fetchProjectData();
  }, [projectName]);

  const validateApiKey = async (apiKey) => {
    try {
      const response = await fetch('https://api.openai.com/v1/engines', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });
      return response.ok;
    } catch (error) {
      console.error('Error validating API key:', error);
      return false;
    }
  };

  const handleApiKeyChange = async (e) => {
    e.preventDefault();
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    setIsValidating(true);
    setError(null);

    try {
      const isValid = await validateApiKey(newApiKey);

      if (!isValid) {
        setError("Invalid API Key. Please check and try again.");
        setIsValidating(false);
        return;
      }

      const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
      await updateDoc(projectRef, { apiKey: newApiKey });
      setNewApiKey('');
      alert('API Key updated successfully');
    } catch (error) {
      console.error('Error updating API Key:', error);
      setError("Error updating API Key");
    } finally {
      setIsValidating(false);
    }
  };

  const handleDeleteProject = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      // Delete the custom hostname from Cloudflare
      const domainUrl = projectData.domainUrl;
      if (domainUrl) {
        await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/deleteHostname', {
          params: { domainUrl }
        });
      }

      // First, deactivate all associated subscriptions
      const subscriptionsRef = collection(firestore, 'customers', currentUser.uid, 'subscriptions');
      const q = query(subscriptionsRef, where('metadata.projectId', '==', projectName));
      const subscriptionsSnapshot = await getDocs(q);

      const batch = writeBatch(firestore);

      subscriptionsSnapshot.forEach((subscriptionDoc) => {
        batch.update(subscriptionDoc.ref, { status: 'inactive' });
      });

      // Then, delete the project
      const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
      batch.delete(projectRef);

      // Commit the batch
      await batch.commit();

      setIsDeleteModalOpen(false);
      navigate('/'); // Redirect to projects list or dashboard
    } catch (error) {
      console.error('Error deleting project:', error);
      setError("Error deleting project and deactivating subscriptions");
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!projectData) {
    return <LoadingScreen />;
  }

  return (
    <div className="project-page">
      <img 
        src={backIcon} 
        alt="Back" 
        className="back-icon" 
        onClick={() => navigate(`/project/${projectName}`)} 
      />
      <h1 className='edit-title'>Project Settings</h1>

      <section className="api-key-section">
        <h2>Update API Key</h2>
        <form onSubmit={handleApiKeyChange}>
          <input
            type="password"
            value={newApiKey}
            onChange={(e) => setNewApiKey(e.target.value)}
            placeholder="Enter new API Key"
            required
          />
          <button type="submit" disabled={isValidating}>
            {isValidating ? 'Validating...' : 'Update API Key'}
          </button>
        </form>
        {error && <p className="error">{error}</p>}
      </section>

      <section className="danger-zone">
        <h2>Danger Zone</h2>
        <button 
          className="delete-project-btn" 
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete Project
        </button>
      </section>

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
      />
    </div>
  );
};

export default ProjectSettingsPage;
