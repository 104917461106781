import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CnameComponent = ({ projectData, onVerified, updateProjectData }) => {
  const [hostnameStatus, setHostnameStatus] = useState('pending');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cnameVerified, setCnameVerified] = useState(false);

  useEffect(() => {
    checkHostnameStatus();
    const interval = setInterval(checkHostnameStatus, 55500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (cnameVerified || hostnameStatus === 'active') {
      updateProjectData({ cnameVerified: true, hostnameStatus: 'active' });
      onVerified();
    }
  }, [cnameVerified, hostnameStatus, onVerified, updateProjectData]);

  const checkHostnameStatus = async () => {
    try {
      const response = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/checkHostnameStatus', {
        params: { domain: `www.${projectData.domainUrl}` },
      });
      setHostnameStatus(response.data.status);
      if (response.data.status === 'active') {
        await updateProjectData({ hostnameStatus: true });
        onVerified(); // Call onVerified when hostname is active
      }
    } catch (error) {
      console.error('Error checking hostname status:', error);
    }
  };

  const verifyCname = async () => {
    setIsLoading(true);
    setVerificationMessage('');
    try {
      const expectedCNAME = `${projectData.proxyCode}.proxy.linguatabs.com`;
      const response = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/verifyCnameRecord', {
        params: { domain: `www.${projectData.domainUrl}`, expectedCNAME }
      });
      
      if (response.data.success) {
        setVerificationMessage('CNAME record verified successfully!');
        await updateProjectData({ cnameVerified: true });
        setCnameVerified(true);
        onVerified(); // Call onVerified when CNAME is verified
      } else {
        setVerificationMessage(`Now would be a great time to work on your patience🫠 ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error verifying CNAME record:', error);
      setVerificationMessage(`Now would be a great time to work on your patience🫠: ${error.response?.data?.error || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  return (
    <div className="form-container">
      <h3>Update CNAME Record</h3>
      <p>Your current CNAME type: {projectData.serverType}</p>
      <p>Please update your CNAME record:</p>
      <table className="cname-table">
        <tbody>
          <tr>
            <td><strong>Current CNAME:</strong></td>
            <td>{projectData.currentCNAME || 'Not available'}</td>
          </tr>
          <tr>
            <td><strong>Name:</strong></td>
            <td>www</td>
          </tr>
          <tr>
            <td><strong>Value:</strong></td>
            <td>
              <span 
                onClick={() => copyToClipboard(`${projectData.proxyCode}.proxy.linguatabs.com`)}
                style={{cursor: 'pointer', textDecoration: 'underline'}}
              >
                {`${projectData.proxyCode}.proxy.linguatabs.com`}
              </span>
              <span> (Click to copy)</span>
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={verifyCname} className="form-button" disabled={isLoading || cnameVerified || hostnameStatus === 'active'}>
        {isLoading ? 'Verifying...' : cnameVerified || hostnameStatus === 'active' ? 'Verified' : 'Verify CNAME'}
      </button>
      {verificationMessage && <p>{verificationMessage}</p>}
      <p>Hostname Status: {hostnameStatus}</p>
      {(cnameVerified || hostnameStatus === 'active') && 
        <p>Verification successful! You can now proceed to the next step.</p>
      }
    </div>
  );
};

export default CnameComponent;