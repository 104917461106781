import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import Modal from './Modal';
import './Modal.css';

const ReferralModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      await addDoc(collection(firestore, 'referrals'), {
        userId: currentUser.uid,
        websiteReferred: email,
        date: new Date()
      });

      setMessage(`Referral sent successfully! You'll get one month free after your first paid month when they sign up.`);
      setEmail('');
    } catch (error) {
      console.error('Error sending referral:', error);
      setMessage('An error occurred while sending the referral. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal-content">
        <h2>Refer a Friend</h2>
        <p>Earn one month free after your first paid month for each friend who signs up for a paid plan!</p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Friend's Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Referral'}
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </Modal>
  );
};

export default ReferralModal;