// ReportBugModal.js
import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import Modal from './Modal';
import './ReportBugModal.css';

const ReportBugModal = ({ isOpen, onClose }) => {
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let imageUrl = '';

      if (image) {
        const storage = getStorage();
        const storageRef = ref(storage, `bug-reports/${image.name}`);
        const snapshot = await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      await addDoc(collection(firestore, 'bugs'), {
        website,
        description,
        imageUrl,
        createdAt: new Date()
      });

      alert('Bug report submitted successfully!');
      onClose();
    } catch (error) {
      console.error('Error submitting bug report:', error);
      alert('Failed to submit bug report.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form className="report-bug-form" onSubmit={handleSubmit}>
        <h2>Report a Bug</h2>
        <label>
          Website Domain:
          <input 
            type="text" 
            value={website} 
            onChange={(e) => setWebsite(e.target.value)} 
            required 
          />
        </label>
        <label>
          Description:
          <textarea 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
            required 
          />
        </label>
        <label>
          Upload Screenshot (optional):
          <input 
            type="file" 
            onChange={handleImageChange} 
          />
        </label>
        <button type="submit" disabled={uploading}>
          {uploading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </Modal>
  );
};

export default ReportBugModal;
