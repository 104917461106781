import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../firebase';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { getCheckoutUrl } from '../../components/stripePayment';
import Loader from '../../components/LoadingScreen'; // Import LoadingScreen
import Modal from '../../components/Modal'; // Adjust the path according to your file structure
import backIcon from '../../assets/back.svg'; // Import the back icon

const SubscriptionPage = () => {
  const { projectName } = useParams();
  const navigate = useNavigate();
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPriceID, setSelectedPriceID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState(null);
  const [domainUrl, setDomainUrl] = useState('');
  const [freePlan, setFreePlan] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.log("No current user found");
        return;
      }

      try {
        const projectDocRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
        const projectDoc = await getDoc(projectDocRef);

        if (projectDoc.exists()) {
          const projectData = projectDoc.data();
          setDomainUrl(projectData.domainUrl || projectName);

          const subscriptionsRef = collection(firestore, 'customers', currentUser.uid, 'subscriptions');
          const q = query(subscriptionsRef, where('metadata.projectId', '==', projectDoc.id), where('status', '==', 'active'));
          const subscriptionSnapshot = await getDocs(q);

          // Fetch all plans
          const plansCollection = collection(firestore, 'plans');
          const plansSnapshot = await getDocs(plansCollection);
          const availablePlans = plansSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log("Fetched plans:", availablePlans);

          if (availablePlans.length === 0) {
            console.error("No plans found in Firestore");
            setError("No subscription plans available. Please contact support.");
          } else {
            // Sort plans by language limit
            availablePlans.sort((a, b) => a.languageLimit - b.languageLimit);
            setPlans(availablePlans);
            console.log("Sorted and set plans:", availablePlans);

            // Find the free plan
            const freePlan = availablePlans.find(plan => plan.languageLimit === 1);
            setFreePlan(freePlan);
            console.log("Free plan:", freePlan);

            if (!subscriptionSnapshot.empty) {
              const subscriptionDoc = subscriptionSnapshot.docs[0];
              const subscriptionData = subscriptionDoc.data();
              setSubscriptionDetails(subscriptionData);

              const priceID = subscriptionData.items[0].price.id;
              const plan = availablePlans.find(plan => plan.priceID === priceID);
              setPlanDetails(plan);
              setCurrentPlan(plan);
              setNextBillingDate(new Date(subscriptionData.current_period_end.seconds * 1000));
              console.log("Active subscription found:", subscriptionData);
              console.log("Current plan details:", plan);
            } else {
              console.log("No active subscription found, user is on free plan");
              setSubscriptionDetails(null);
              setPlanDetails(null);
              setCurrentPlan(null);
            }
          }
        } else {
          console.error('Project does not exist.');
        }
      } catch (error) {
        console.error('Error fetching subscription data:', error);
        setError(`Error fetching subscription data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [projectName]);

  const handlePlanChange = (priceID) => {
    setSelectedPriceID(priceID);
    setShowModal(true);
  };

  const proceedToCheckout = async () => {
    setShowModal(false);
    const currentUser = auth.currentUser;
    const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
    const projectDoc = await getDoc(projectRef);
    const checkoutUrl = await getCheckoutUrl(selectedPriceID, projectDoc.id);
    window.location.href = checkoutUrl;
  };

  console.log("Current plans state:", plans);
  console.log("Current planDetails state:", planDetails);
  console.log("Current subscriptionDetails state:", subscriptionDetails);

  if (loading) {
    return <Loader/>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const currentLanguageLimit = subscriptionDetails && planDetails ? planDetails.languageLimit : 1;

  return (
    <div className="project-page">
      <img 
        src={backIcon} 
        alt="Back" 
        className="back-icon" 
        onClick={() => navigate(`/project/${projectName}`)} 
      />
      <div className='subscription-page'>
        <div className='subscription-card'>
          <h1>Upgrade Your Subscription Plan for {domainUrl}</h1>
          {currentPlan ? (
            <div>
              <p><strong>Current Plan:</strong> {currentPlan.name}</p>
              <p><strong>Languages Allowed:</strong> {currentPlan.languageLimit}</p>
              <p><strong>Expiration Date:</strong> {nextBillingDate.toLocaleDateString()}</p>
            </div>
          ) : (
            <div>
              <p><strong>Current Plan:</strong> Free</p>
              <p><strong>Features:</strong> Home page translation only</p>
              <p><strong>Price:</strong> $0 per month</p>
            </div>
          )}
        </div>
        <div className="plans-list">
          {plans.map(plan => (
            <button 
              key={plan.priceID} 
              onClick={() => handlePlanChange(plan.priceID)}
              disabled={currentPlan && plan.languageLimit === currentPlan.languageLimit}
              className={currentPlan && plan.languageLimit === currentPlan.languageLimit ? 'current-plan' : ''}
            >
              {plan.languageLimit === 1 ? (
                <>Unlock Translation Editing - ${(Number(plan.monthlyPrice)).toFixed(2)} per month</>
              ) : (
                <>{plan.name} - {plan.languageLimit} Languages - ${(Number(plan.monthlyPrice)).toFixed(2)} per month</>
              )}
              {currentPlan && plan.languageLimit === currentPlan.languageLimit && ' (Current Plan)'}
            </button>
          ))}
        </div>
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <h2>Confirm Subscription Change</h2>
          <p>Your new subscription plan will start now and your current subscription "{subscriptionDetails?.metadata?.subscriptionName || 'Subscription'}" will be removed.</p>
          <button onClick={proceedToCheckout}>Confirm</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
          <p style={{ fontSize: 'smaller' }}>If you prefer to wait closer to the next billing cycle ({nextBillingDate?.toLocaleDateString()}), you can cancel the current operation.</p>
        </Modal>
      </div>
    </div>
  );
};

export default SubscriptionPage;
