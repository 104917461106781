import React, { useState } from 'react';
import axios from 'axios';
import helpIcon from '../../assets/help.svg'; // Adjust the path as needed

function APIKeyComponent({ onNext, onProjectDataChange, apiKey }) {
  const [localApiKey, setLocalApiKey] = useState(apiKey || '');
  const [error, setError] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const handleChangeApiKey = (e) => {
    setLocalApiKey(e.target.value);
    setError('');
    onProjectDataChange({ apiKey: e.target.value });
  };

  const validateApiKey = async (key) => {
    try {
      const response = await axios.get('https://api.openai.com/v1/engines', {
        headers: {
          'Authorization': `Bearer ${key}`,
          'Content-Type': 'application/json',
        },
      });
      return response.status === 200;
    } catch (error) {
      console.error('Error validating API key:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsValidating(true);

    const isValid = await validateApiKey(localApiKey);

    if (isValid) {
      onNext();
    } else {
      setError('Invalid API key. Please check and try again.');
    }

    setIsValidating(false);
  };

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h2 className="form-title">Set up your OpenAI API Key</h2>
        <p className="form-description">Enter your OpenAI API key to enable translations</p>
        <div className="form-group">
          <label htmlFor="apiKey" className="form-label">
            OpenAI API Key:
            <img
              src={helpIcon}
              alt="Help"
              className="help-icon"
              onClick={toggleHelp}
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          </label>
          <input
            id="apiKey"
            type="password"
            value={localApiKey}
            className="form-input"
            onChange={handleChangeApiKey}
            required
          />
          {error && <p className="error-message">{error}</p>}
        </div>

        {showHelp && (
          <div className="help-section">
            <h3>How to get your OpenAI API Key</h3>
            <ol>
              <li>Go to the <a style={{ color: '#4FFF8A' }} href="https://platform.openai.com/login?launch" target="_blank" rel="noopener noreferrer">OpenAI Platform</a> and log in.</li>
              <li>Navigate to the API Keys section in your account settings.</li>
              <li>Create a new API key if you don't already have one.</li>
              <li>Add payment method to cover API cost.</li>
              <li>Copy the API key and paste it into the input field above.</li>
            </ol>
          </div>
        )}

        <div className="form-buttons">
          <button type="submit" className="form-button" disabled={isValidating}>
            {isValidating ? 'Validating...' : 'Next'}
          </button>
        </div>
      </form>
    </div>
  );
}

export default APIKeyComponent;
