import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import TxtComponent from './final/TxtComponent';
import CnameComponent from './final/CnameComponent';
import Configuration from './final/Configuration';
import LoadingScreen from '../LoadingScreen';

const FinalStep = ({ projectId, onCNAMEConfigured }) => {
  const [step, setStep] = useState(1);
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
        fetchProjectData(user);
      } else {
        setError('User not authenticated');
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [projectId]);

  const fetchProjectData = async (user) => {
    if (!projectId) {
      setError('Project ID not provided');
      setLoading(false);
      return;
    }

    try {
      const projectDocRef = doc(firestore, 'customers', user.uid, 'projects', projectId);
      const projectDoc = await getDoc(projectDocRef);
      if (projectDoc.exists()) {
        const data = projectDoc.data();
        await updateCnameInfo(data, projectDocRef);
      } else {
        setError('Project not found');
      }
    } catch (error) {
      console.error('Error fetching project data:', error);
      setError(`Error fetching project data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const updateCnameInfo = async (data, projectDocRef) => {
    try {
      // Only fetch and update CNAME info if it doesn't exist or if serverType is not set
      if (!data.currentCNAME || !data.serverType) {
        const currentCNAMEResponse = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/getCurrentCNAME', {
          params: { domain: `www.${data.domainUrl}` },
        });

        if (currentCNAMEResponse.data && currentCNAMEResponse.data.currentCNAME) {
          const currentCNAME = currentCNAMEResponse.data.currentCNAME;

          const cnameTypeResponse = await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/checkCnameType', {
            params: { domain: `www.${data.domainUrl}`, currentCNAME },
          });

          const updatedData = {
            ...data,
            currentCNAME,
            serverType: cnameTypeResponse.data.type,
          };

          await updateDoc(projectDocRef, updatedData);
          setProjectData(updatedData);
        } else {
          setProjectData(data);
        }
      } else {
        setProjectData(data);
      }
    } catch (error) {
      console.error('Error updating CNAME info:', error);
      setProjectData(data);
    }
  };

  const updateProjectData = async (newData) => {
    if (!currentUser || !projectId) return;

    try {
      const projectDocRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectId);
      await updateDoc(projectDocRef, newData);
      setProjectData(prevData => ({ ...prevData, ...newData }));
    } catch (error) {
      console.error('Error updating project data:', error);
    }
  };

  const handleTxtVerified = () => {
    setStep(2);
  };

  const handleCnameVerified = () => {
    setStep(3);
  };

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  switch (step) {
    case 1:
      return <TxtComponent projectData={projectData} onVerified={handleTxtVerified} updateProjectData={updateProjectData} />;
    case 2:
      return <CnameComponent projectData={projectData} onVerified={handleCnameVerified} updateProjectData={updateProjectData} />;
      case 3:
        return <Configuration 
          projectData={projectData} 
          projectId={projectId} 
          onConfigured={onCNAMEConfigured} 
          updateProjectData={updateProjectData}
        />;
    default:
      return null;
  }
};

export default FinalStep;