import React, { useState } from 'react';
import axios from 'axios';

const languages = [
  { name: 'English', code: 'en' },
  { name: 'Spanish', code: 'es' },
  { name: 'French', code: 'fr' },
  { name: 'German', code: 'de' },
  { name: 'Italian', code: 'it' },
  { name: 'Portuguese', code: 'pt' },
  { name: 'Russian', code: 'ru' },
  { name: 'Chinese', code: 'zh' },
  { name: 'Japanese', code: 'ja' },
  { name: 'Korean', code: 'ko' }
];

function ConfigProject({ onBack, onProjectDataChange, onNext, domainUrl, originalLanguage, translatedLanguages }) {
  const [localDomainUrl, setLocalDomainUrl] = useState(domainUrl || '');
  const [localOriginalLanguage, setLocalOriginalLanguage] = useState(originalLanguage || '');
  const [localTranslatedLanguage, setLocalTranslatedLanguage] = useState(translatedLanguages?.[0] || '');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const normalizeDomain = (domainUrl) => {
    return domainUrl.replace(/^http(s)?:\/\//, '').replace(/^www\./, '').split('/')[0];
  };

  const handleDomainChange = (e) => {
    const newDomain = e.target.value;
    setLocalDomainUrl(newDomain);
    setError('');
    onProjectDataChange({ domainUrl: newDomain });
  };

  const handleOriginalLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLocalOriginalLanguage(newLanguage);
    onProjectDataChange({ originalLanguage: newLanguage });
  };

  const handleTranslatedLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLocalTranslatedLanguage(newLanguage);
    onProjectDataChange({ translatedLanguages: [newLanguage] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const normalizedDomain = normalizeDomain(localDomainUrl);

    try {
      const response = await axios.post('https://us-central1-lingua-tabs.cloudfunctions.net/addHostname', {
        domainUrl: `www.${normalizedDomain}`
      });
      const data = response.data;
      if (data.success) {
        console.log('Hostname added successfully.');
        onNext({
          domainUrl: normalizedDomain,
          originalLanguage: localOriginalLanguage,
          translatedLanguages: [localTranslatedLanguage],
          hostnameAdded: true,
          projectLocked: true,
        });
      } else {
        console.log('Error adding hostname:', data.error);
        setError(data.error || 'Failed to add hostname on Cloudflare: ' + (data.errors ? data.errors[0].message : 'Unknown error'));
      }
    } catch (error) {
      console.error('Error adding hostname:', error);
      setError('Error adding hostname: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h1 className="form-title">Configure Your Multilingual Settings</h1>
        <p className="form-description">Enter your website's details to start the translation process.</p>

        <div className="form-group">
          <label htmlFor="domainUrl" className="form-label">Domain URL:</label>
          <input
            id="domainUrl"
            type="text"
            className="form-input"
            placeholder="example.com"
            value={localDomainUrl}
            onChange={handleDomainChange}
            required
            disabled={loading}
          />
          <small>Enter your website's domain name.</small>
        </div>

        <div className="form-group">
          <label htmlFor="originalLanguage" className="form-label">Original Language:</label>
          <select
            id="originalLanguage"
            className="form-select"
            value={localOriginalLanguage}
            onChange={handleOriginalLanguageChange}
            required
            disabled={loading}
          >
            <option value="">Select Language</option>
            {languages.map(lang => (
              <option key={lang.code} value={lang.code}>{lang.name}</option>
            ))}
          </select>
          <small>What is the original (current) language of your website?</small>
        </div>

        <div className="form-group">
          <label htmlFor="translatedLanguage" className="form-label">Translated Language:</label>
          <select
            id="translatedLanguage"
            className="form-select"
            value={localTranslatedLanguage}
            onChange={handleTranslatedLanguageChange}
            required
            disabled={loading}
          >
            <option value="">Select Language</option>
            {languages.filter(lang => lang.code !== localOriginalLanguage).map(lang => (
              <option key={lang.code} value={lang.code}>{lang.name}</option>
            ))}
          </select>
          <small>Select one language to translate to.</small>
        </div>

        <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button type="button" onClick={onBack} className="form-button" disabled={loading}>Back</button>
          <button type="submit" className="form-button" disabled={loading || error || !localDomainUrl || !localOriginalLanguage || !localTranslatedLanguage}>
            {loading ? 'Processing...' : 'Next'}
          </button>
        </div>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default ConfigProject;