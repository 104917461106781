import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/AuthPage';
import Dashboard from './components/Dashboard';
import ProjectSetup from './pages/ProjectSetup';
import ProjectPage from './pages/Projects';
import PaymentStatusPopup from './components/projects/PaymentStatusPopup';
import LoadingScreen from './components/LoadingScreen';
import TranslationPage from './pages/project/TranslationPage';
import SubscriptionPage from './pages/project/SubscriptionPage';
import ProjectSettingsPage from './pages/project/ProjectSettingsPage';
import Settings from './pages/Settings';
import EditTranslations from './pages/project/EditTranslations';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import { collection, query, getDocs } from 'firebase/firestore';
import { auth, firestore } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { CustomerPlanProvider, useCustomerPlan } from './context/CustomerPlanContext';
import setRandomLinkColor from './utils/linkColorRandomizer';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useCustomerPlan();
  const location = useLocation();

  if (loading) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

function App() {
  const [popupOpen, setPopupOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const { user, userProjects, setUser, setUserProjects, loading, setLoading } = useCustomerPlan();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const projectsRef = collection(firestore, 'customers', currentUser.uid, 'projects');
          const projectsQuery = query(projectsRef);
          const querySnapshot = await getDocs(projectsQuery);
          const projects = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setUserProjects(projects);
        } catch (error) {
          console.error("Error fetching projects:", error);
          setUserProjects([]);
        }
      } else {
        setUserProjects([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [setUser, setUserProjects, setLoading]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const status = params.get('status');
    if (status === 'success' || status === 'cancel') {
      setPaymentStatus(status);
      setPopupOpen(true);
    }
  }, [location.search]);

  useEffect(() => {
    setRandomLinkColor();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
        <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
          <Route index element={userProjects.length ? <HomePage /> : <Navigate to="/projects/setup" state={{ noProjects: true }} />} />
          <Route path="projects/setup" element={<ProjectSetup />} />
          <Route path="projects/setup/:projectName" element={<ProjectSetup />} />
          <Route path="project/:projectName" element={<ProjectPage />} />
          <Route path="project/:projectName/translate" element={<TranslationPage />} />
          <Route path="project/:projectName/subscription" element={<SubscriptionPage />} />
          <Route path="project/:projectName/settings" element={<ProjectSettingsPage />} />
          <Route path="project/:projectName/translate/:langCode/:subcollectionName" element={<EditTranslations />} />
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <PaymentStatusPopup isOpen={popupOpen} status={paymentStatus} onClose={() => setPopupOpen(false)} />
    </>
  );
}

const AppWrapper = () => (
  <Router>
    <CustomerPlanProvider>
      <App />
    </CustomerPlanProvider>
  </Router>
);

export default AppWrapper;