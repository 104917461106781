import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { collection, doc, deleteDoc, query, where, getDocs, updateDoc, getDoc, limit, addDoc } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import CreateProject from '../components/projects/CreateProject';
import ConfigProject from '../components/projects/ConfigProject';
import axios from 'axios';
import APIKeyComponent from '../components/projects/APIKeyComponent';
import FinalStep from '../components/projects/FinalStep';
import DeleteConfirmationModal from '../components/projects/DeleteConfirmationModal';
import './ProjectSetup.css';
import trashIcon from '../assets/trash.svg';
import LoadingScreen from '../components/LoadingScreen';

const initialProjectData = {
  projectName: '',
  domainUrl: '',
  originalLanguage: '',
  translatedLanguages: [],
  currentStep: 1,
  proxyCode: '',
  txtVerificationCode: '',
  txtVerificationValue: '',
  apiKey: '',
};

const determineStep = (data) => {
  if (!data.domainUrl) return 2;
  if (!data.apiKey) return 3;
  if (!data.cnameConfigured) return 4;
  return 1; // Default to step 1 if all steps are complete (shouldn't happen)
};

function ProjectSetup() {
  const [step, setStep] = useState(1);
  const [projectData, setProjectData] = useState(initialProjectData);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { projectName } = useParams();

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setLoading(false);
        return;
      }
  
      try {
        const projectsRef = collection(firestore, 'customers', currentUser.uid, 'projects');
        
        // First, check for any incomplete project
        const incompleteQuery = query(
          projectsRef, 
          where("projectLocked", "!=", true),
          limit(1)
        );
        const incompleteSnapshot = await getDocs(incompleteQuery);
  
        if (!incompleteSnapshot.empty) {
          const incompleteProject = incompleteSnapshot.docs[0];
          const data = incompleteProject.data();
          setProjectData(data);
          setProjectId(incompleteProject.id);
          const calculatedStep = determineStep(data);
          setStep(calculatedStep);
        } else if (projectName) {
          const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
          const projectDoc = await getDoc(projectRef);
          if (projectDoc.exists()) {
            const data = projectDoc.data();
            setProjectData(data);
            setProjectId(projectDoc.id);
            if (data.onboardingComplete) {
              navigate(`/project/${projectDoc.id}`);
              return;
            }
            const calculatedStep = determineStep(data);
            setStep(calculatedStep);
          } else {
            resetProjectData();
          }
        } else if (location.state?.isNewProject || location.state?.noProjects) {
          resetProjectData();
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
      setLoading(false);
    };
  
    fetchProjectData();
  }, [projectName, navigate, location.state]);

  const generateUniqueProxyCode = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return null;

    while (true) {
      const proxyCode = Math.floor(100000 + Math.random() * 900000).toString();
      const projectsRef = collection(firestore, 'customers', currentUser.uid, 'projects');
      const q = query(projectsRef, where('proxyCode', '==', proxyCode));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return proxyCode;
      }
    }
  };

  const resetProjectData = () => {
    setProjectData(initialProjectData);
    setStep(1);
    localStorage.removeItem('projectData');
  };

  const normalizeDomain = (domainUrl) => {
    return domainUrl.replace(/^http(s)?:\/\//, '').replace(/^www\./, '').split('/')[0];
  };

  const saveProjectData = async (data) => {
    let projectDataToSave = { ...data };
    if (data.domainUrl) {
      const normalizedDomain = normalizeDomain(data.domainUrl);
      projectDataToSave = { ...projectDataToSave, domainUrl: normalizedDomain };
    }

    if (data.onboardingComplete) {
      projectDataToSave.tier = 'free';
    }

    const currentUser = auth.currentUser;
    if (currentUser) {
      const projectCollection = collection(firestore, 'customers', currentUser.uid, 'projects');
      
      if (projectId) {
        const projectRef = doc(projectCollection, projectId);
        await updateDoc(projectRef, projectDataToSave);
      } else {
        projectDataToSave.proxyCode = await generateUniqueProxyCode();
        const newProjectDoc = await addDoc(projectCollection, projectDataToSave);
        setProjectId(newProjectDoc.id);
        projectDataToSave.id = newProjectDoc.id;
      }
      
      localStorage.setItem('projectData', JSON.stringify(projectDataToSave));
      setProjectData(projectDataToSave);
    }
  };

  const handleNextStep = async (newData = {}) => {
    const newStep = step + 1;
    const updatedData = { 
      ...projectData, 
      ...newData, 
      currentStep: newStep,
      projectLocked: newStep >= 3 ? true : projectData.projectLocked
    };
    await saveProjectData(updatedData);
    setStep(newStep);
    setProjectData(updatedData);
  };

  const handleBackStep = async () => {
    if (step > 1 && step !== 3) {
      const newStep = step - 1;
      await saveProjectData({ ...projectData, currentStep: newStep });
      setStep(newStep);
    }
  };

  const handleLockProject = async () => {
    const updatedData = {
      ...projectData,
      projectLocked: true,
      onboardingComplete: true
    };
    await saveProjectData(updatedData);
    navigate(`/project/${projectId}`, { replace: true });
  };

  const handleDeleteProject = async () => {
    const currentUser = auth.currentUser;
    if (currentUser && projectId) {
      try {
        const domainUrl = projectData.domainUrl;
        if (domainUrl) {
          await axios.get('https://us-central1-lingua-tabs.cloudfunctions.net/deleteHostname', {
            params: { domainUrl }
          });
        }
  
        await deleteDoc(doc(firestore, 'customers', currentUser.uid, 'projects', projectId));
        resetProjectData();
        setIsDeleteModalOpen(false);
        setProjectId(null);
        navigate('/projects/setup');
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    } else {
      console.error('No projectId or user is not authenticated.');
    }
  };

  const handleProjectDataChange = (newData) => {
    setProjectData(prevData => {
      const updatedData = { ...prevData, ...newData };
      return JSON.stringify(updatedData) !== JSON.stringify(prevData) ? updatedData : prevData;
    });
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <CreateProject
            onNext={(newData) => handleNextStep(newData)}
            onProjectDataChange={handleProjectDataChange}
            projectName={projectData.projectName}
            projectId={projectId}
          />
        );
      case 2:
        return (
          <ConfigProject
            onBack={handleBackStep}
            onProjectDataChange={handleProjectDataChange}
            onNext={async (data) => {
              const updatedData = {
                ...projectData,
                ...data,
                hostnameAdded: true,
              };
              await saveProjectData(updatedData);
              setProjectData(updatedData);
              handleNextStep();
            }}
            domainUrl={projectData.domainUrl}
            originalLanguage={projectData.originalLanguage}
            translatedLanguages={projectData.translatedLanguages}
          />
        );
      case 3:
        return (
          <APIKeyComponent
            onNext={handleNextStep}
            onProjectDataChange={handleProjectDataChange}
            apiKey={projectData.apiKey}
          />
        );
      case 4:
        return (
          <FinalStep
            projectId={projectId}
            projectData={projectData}
            onCNAMEConfigured={async () => {
              const updatedData = {
                ...projectData,
                cnameConfigured: true,
              };
              await saveProjectData(updatedData);
              setProjectData(updatedData);
              handleLockProject();
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderProgressBar = () => {
    const steps = [
      { label: 'Project created', step: 2 },
      { label: 'Setup', step: 3 },
      { label: 'API Key', step: 4 },
    ];

    return (
      <div className="progress-bar">
        {steps.map((stepInfo, index) => {
          if (step >= stepInfo.step) {
            return (
              <div key={index} className="progress-step">
                <span>{stepInfo.label}</span>
                {step >= 3 && stepInfo.step === 3 && (
                  <img 
                    src={trashIcon} 
                    alt="Delete project" 
                    onClick={() => setIsDeleteModalOpen(true)} 
                    className="delete-icon" 
                  />
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="project-page">
      {renderProgressBar()}
      {renderStep()}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
      />
    </div>
  );
}

export default ProjectSetup;
