import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import './TranslationPage.css';
import Loader from '../../components/LoadingScreen';
import backIcon from '../../assets/back.svg';

const EditTranslations = () => {
  const { projectName, langCode, subcollectionName } = useParams();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [newText, setNewText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('No authenticated user found');
        }

        const subcollectionRef = collection(firestore, 'customers', currentUser.uid, 'projects', projectName, 'translations', langCode, subcollectionName);
        const snapshot = await getDocs(subcollectionRef);

        if (snapshot.empty) {
          setDocuments([]);
          setError("No translations found.");
        } else {
          const fetchedDocuments = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setDocuments(fetchedDocuments);
          setFilteredDocuments(fetchedDocuments);
        }
      } catch (err) {
        setError(`Failed to fetch data: ${err.message}`);
        console.error("Error fetching documents:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectName, langCode, subcollectionName]);

  useEffect(() => {
    setFilteredDocuments(
      documents.filter(doc => 
        doc.originalText.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.translatedText.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, documents]);

  const handleEdit = async (id, newText) => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }

      const docRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName, 'translations', langCode, subcollectionName, id);
      await updateDoc(docRef, { translatedText: newText });

      setDocuments(docs =>
        docs.map(doc => (doc.id === id ? { ...doc, translatedText: newText } : doc))
      );
      setFilteredDocuments(docs =>
        docs.map(doc => (doc.id === id ? { ...doc, translatedText: newText } : doc))
      );
      setEditingId(null);
    } catch (err) {
      console.error("Error updating document:", err);
      setError(`Failed to update translation: ${err.message}`);
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="translation-page">
      <img 
        src={backIcon} 
        alt="Back" 
        className="back-icon" 
        onClick={() => navigate(-1)} 
      />
      <h1 className='edit-title'>Translations for {subcollectionName}</h1>
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Search translations..." 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
      </div>
      <div className="translations-grid">
        {filteredDocuments.length > 0 ? (
          filteredDocuments.map(doc => (
            <div 
              key={doc.id} 
              className="translation-card" 
              onClick={() => {
                setEditingId(doc.id);
                setNewText(doc.translatedText);
              }}
            >
              <div className="original-text">{doc.originalText}</div>
              <div className="translated-text">
                {editingId === doc.id ? (
                  <input 
                    type="text" 
                    value={newText} 
                    onChange={(e) => setNewText(e.target.value)} 
                    onBlur={() => handleEdit(doc.id, newText)}
                    autoFocus
                  />
                ) : (
                  doc.translatedText
                )}
              </div>
            </div>
          ))
        ) : <p>No translations available.</p>}
      </div>
    </div>
  );
};

export default EditTranslations;