import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, firestore, functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc, collection, getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import Loader from '../../components/LoadingScreen';
import './TranslationPage.css';
import backIcon from '../../assets/back.svg';

const colors = ['#B63ACE', '#4B9FFF', '#4FFF8A', '#FF7A79', '#FFD650'];

const languageCodeToName = {
  'es': 'Spanish',
  'de': 'German',
  'fr': 'French',
  'zh': 'Chinese',
  'ja': 'Japanese',
  'pt': 'Portuguese',
  'ar': 'Arabic',
  'en': 'English'
};

const TranslationPage = () => {
  const { projectName: projectId } = useParams();
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = useCallback(async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log("No authenticated user found");
      setLoading(false);
      return;
    }
  
    console.log(`Fetching data for projectId: ${projectId}`);
    console.log(`Current user ID: ${currentUser.uid}`);
  
    try {
      const projectRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectId);
      console.log(`Project reference path: ${projectRef.path}`);
      
      const projectDoc = await getDoc(projectRef);
  
      if (!projectDoc.exists()) {
        console.log(`Project "${projectId}" not found`);
        setLoading(false);
        return;
      }
  
      const projectData = projectDoc.data();
      const projectName = projectData.projectName || 'Unnamed Project';
      setProjectName(projectName);
      console.log(`Project name: ${projectName}`);
  
      const translationsRef = collection(projectRef, 'translations');
      console.log(`Translations reference path: ${translationsRef.path}`);
      
      const translationsSnapshot = await getDocs(translationsRef);
      const existingLanguages = translationsSnapshot.docs.map(doc => doc.id);
  
      console.log(`Existing language documents: ${existingLanguages.join(', ')}`);
      console.log(`Selected languages in project: ${projectData.translatedLanguages.join(', ')}`);
  
      const translationsData = {};
      const currentLanguages = projectData.translatedLanguages.map(lang => languageCodeToName[lang] || lang);
      const languagesToCreate = currentLanguages.filter(lang => !existingLanguages.includes(lang));
      const languagesToDelete = existingLanguages.filter(lang => !currentLanguages.includes(lang));
  
      console.log(`Languages to create: ${languagesToCreate.join(', ')}`);
      console.log(`Languages to delete: ${languagesToDelete.join(', ')}`);
  
      // Delete language documents that are no longer needed
      for (const lang of languagesToDelete) {
        console.log(`Deleting language document for: ${lang}`);
        await deleteDoc(doc(translationsRef, lang));
        console.log(`Deleted document for ${lang} from translations collection`);
      }
  
      // Create missing language documents
      for (const lang of languagesToCreate) {
        console.log(`Creating language document for: ${lang}`);
        await setDoc(doc(translationsRef, lang), { initialized: true });
        console.log(`Created document for ${lang} in translations collection`);
        translationsData[lang] = [];
      }
  
      // Fetch all language documents (including newly created ones, excluding deleted ones)
      const updatedTranslationsSnapshot = await getDocs(translationsRef);
      console.log(`Total language documents after creation/deletion: ${updatedTranslationsSnapshot.size}`);
  
      for (const langDoc of updatedTranslationsSnapshot.docs) {
        const langName = langDoc.id;
        console.log(`Processing language: ${langName}`);
        
        translationsData[langName] = { data: langDoc.data(), subcollections: {} };
  
        // Call the Cloud Function to list subcollections
        const listSubcollections = httpsCallable(functions, 'listSubcollections');
        console.log(`Calling listSubcollections for langCode: ${langName}`);
        try {
          const result = await listSubcollections({
            customerId: currentUser.uid,
            projectId: projectId,
            langCode: langName
          });
          console.log(`listSubcollections result: ${JSON.stringify(result.data)}`);
  
          if (result.data && result.data.subcollections) {
            const subcollections = result.data.subcollections;
            console.log(`Subcollections for ${langName}: ${JSON.stringify(subcollections)}`);
  
            translationsData[langName].subcollections = subcollections.reduce((acc, subcolName) => {
              acc[subcolName] = [];
              return acc;
            }, {});
          } else {
            console.log(`No subcollections found for langCode: ${langName}`);
          }
        } catch (callableError) {
          console.error('Error calling listSubcollections:', callableError);
        }
      }
  
      console.log('Final translations data:', translationsData);
      console.log('Languages in final translations data:', Object.keys(translationsData).join(', '));
      setTranslations(translationsData);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredTranslations = Object.entries(translations)
  .filter(([langCode]) => !selectedLanguage || langCode === selectedLanguage)
  .reduce((acc, [langCode, { subcollections }]) => {
    const filteredSubcollections = Object.keys(subcollections)
      .filter(subcolName => !searchTerm || subcolName.toLowerCase().includes(searchTerm.toLowerCase()))
      .map(subcolName => ({ langCode, subcolName }));

    return [...acc, ...filteredSubcollections];
  }, []);

  const getColor = useCallback((langCode) => {
    const index = Object.keys(translations).indexOf(langCode);
    return colors[index % colors.length];
  }, [translations]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="translation-page">
      <img
        src={backIcon}
        alt="Back"
        className="back-icon"
        onClick={() => navigate(`/project/${projectId}`)}
      />
      <h1 className='edit-title'>Project: {projectName}</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search pages..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="language-chips">
        {Object.keys(translations).map((langCode) => (
          <div
            key={langCode}
            className={`language-chip ${selectedLanguage === langCode ? 'active' : ''}`}
            style={{ backgroundColor: getColor(langCode) }}
            onClick={() => setSelectedLanguage(langCode === selectedLanguage ? null : langCode)}
          >
            {langCode}
          </div>
        ))}
      </div>
      {filteredTranslations.length > 0 ? (
        <div className="pages-grid">
          {filteredTranslations.map(({ langCode, subcolName }) => (
            <div
              key={`${langCode}-${subcolName}`}
              className="page-item"
              style={{ border: `1px solid ${getColor(langCode)}` }}
              onClick={() => navigate(`/project/${projectId}/translate/${langCode}/${subcolName}`)}
            >
              <span>{subcolName}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-translations">No pages translated yet. Start translating to see them here!</div>
      )}
    </div>
  );
};

export default TranslationPage;