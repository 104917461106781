import React from 'react';
import './DashboardHeader.css';
import CartIcon from '../../assets/shop.svg';  // Update path as necessary
import NotificationIcon from '../../assets/bell.svg';  // Update path as necessary
import MenuIcon from '../../assets/menu.svg';  // Update path as necessary

const DashboardHeader = ({ title, onUpgradeClick }) => {
  const [isOpen, setIsOpen] = React.useState(false); // State to track dropdown visibility

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className="dashboard-header">
      <div className="dashboard-title">
        <h2>{title}</h2>
      </div>
      <nav className={`dashboard-nav ${isOpen ? 'open' : ''}`}>  {/* Add conditional class */}
        <ul>
          <li><a href="/billing">Billing</a></li>
          <li><a href="/translations">Translations</a></li>
          <li><a href="/logs">Logs</a></li>
        </ul>
      </nav>
      <div className="dashboard-actions">
        <a href="https://www.wastefree.com/" target="_blank" rel="noopener noreferrer">
        </a>
        <img src={CartIcon} alt='cart icon' className="icon" />
        <img src={NotificationIcon} alt='notification icon' className="icon" />
        <button className="menu-button" onClick={toggleDropdown}>
          <img src={MenuIcon} alt='menu icon' className="icon" />
        </button>
      </div>
    </div>
  );
}

export default DashboardHeader;
