import React, { useState } from 'react';
import DemoLogo from '../assets/logo.svg';
import Background from '../components/DynamicBackground';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import googleLogo from '../assets/google.svg';
import './AuthPage.css';

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);

  const auth = getAuth();

  const createUserInFirestore = async (user, provider) => {
    const userRef = doc(firestore, 'customers', user.uid);
    await setDoc(userRef, {
      uid: user.uid,
      email: user.email,
      name: provider === 'google' ? user.displayName : name,
      createdAt: new Date(),
      provider,
      projects: []
    });
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      if (userCredential.additionalUserInfo && userCredential.additionalUserInfo.isNewUser) {
        await createUserInFirestore(user, 'google');
      }
    } catch (error) {
      console.error('Google Sign Up Error:', error.message);
      setError(error.message);
    }
  };

  const handleEmailPasswordSignUp = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await createUserInFirestore(user, 'email');
    } catch (error) {
      console.error('Email Password Sign Up Error:', error.message);
      setError(error.message);
    }
  };

  const handleEmailPasswordLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Email Password Login Error:', error.message);
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, resetPasswordEmail);
      setForgotPasswordError("Email sent. Check your inbox.");
    } catch (error) {
      console.error('Forgot Password Error:', error.message);
      setForgotPasswordError(error.message);
    }
  };

  return (
    <div className="auth-container">
      <Background />
      <div className="login-side">
        <div className="login-card">
          <img src={DemoLogo} alt="logo" className="logo" />
          {isLogin ? (
            <>
              <h1>Welcome Back</h1>
              <label className='auth-label'>Email</label>
              <input
                className="input-login"
                type="email"
                placeholder='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className='auth-label'>Password</label>
              <input
                className="input-login"
                type="password"
                placeholder='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="login-actions">
                <button className="login-button" onClick={handleEmailPasswordLogin}>Login</button>
                <a href="#" onClick={() => setShowResetPassword(true)} className="forgot-password-link">Forgot password?</a>
              </div>
            </>
          ) : (
            <>
              <h1>Create New Account</h1>
              <label className='auth-label'>Full Name</label>
              <input
                className="input-sign"
                type="text"
                placeholder='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label className='auth-label'>Email</label>
              <input
                className="input-sign"
                type="email"
                placeholder='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className='auth-label'>Password</label>
              <input
                className="input-sign"
                type="password"
                placeholder='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className='auth-label'>Confirm Password</label>
              <input
                className="input-sign"
                type="password"
                placeholder='confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="terms-checkbox">
                <input
                  type="checkbox"
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                <span>Agree to terms</span>
              </div>
              <button className="login-button" onClick={handleEmailPasswordSignUp}>Sign Up</button>
            </>
          )}

          {error && <div className="error-message">{error}</div>}
          <div className="or-divider"><span>or</span></div>
          <button onClick={handleGoogleSignUp} className="google-button">
            <img src={googleLogo} alt="Google Logo" className="google-logo" />
            Sign up with Google
          </button>

          {isLogin ? (
            <span>Don't have an account yet? <a href="#" onClick={() => setIsLogin(false)}>Sign Up</a></span>
          ) : (
            <span>Already have an account? <a href="#" onClick={() => setIsLogin(true)}>Login</a></span>
          )}
        </div>
      </div>
      {showResetPassword && (
        <div className="modal-overlay">
          <div className="modal-container">
            <h3>Reset Password</h3>
            <p>Enter your email address and we will send you a link to reset your password.</p>
            <input
              type="email"
              value={resetPasswordEmail}
              onChange={(e) => setResetPasswordEmail(e.target.value)}
              placeholder="Enter your email"
            />
            {forgotPasswordError && <div className="error-message">{forgotPasswordError}</div>}
            <div className="modal-actions">
              <button onClick={handleForgotPassword}>Send Reset Email</button>
              <button onClick={() => setShowResetPassword(false)} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPage;
