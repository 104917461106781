import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdjustLanguagesModal from '../components/AdjustLanguagesModal';
import { auth, firestore } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import editIcon from '../assets/edit.svg';
import lockIcon from '../assets/lock.svg';
import LoadingScreen from '../components/LoadingScreen';
import './Projects.css';

const ProjectPage = () => {
  const { projectName } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState(null);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [showAdjustLanguagesModal, setShowAdjustLanguagesModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error('No authenticated user found');
  
        const projectDocRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectName);
        const projectDoc = await getDoc(projectDocRef);
  
        if (!projectDoc.exists()) throw new Error('Project does not exist.');
  
        const projectData = { id: projectDoc.id, ...projectDoc.data() };
        setProjectData(projectData);
  
        const subscriptionsRef = collection(firestore, 'customers', currentUser.uid, 'subscriptions');
        const subscriptionQuery = query(
          subscriptionsRef,
          where('metadata.projectId', '==', projectData.id),
          where('status', '==', 'active')
        );
        const subscriptionSnapshot = await getDocs(subscriptionQuery);
  
        if (!subscriptionSnapshot.empty) {
          setSubscriptionActive(true);
          const subscriptionData = subscriptionSnapshot.docs[0].data();
  
          setSubscriptionDetails(subscriptionData);
  
          const plansCollection = collection(firestore, 'plans');
          const plansSnapshot = await getDocs(plansCollection);
          const plans = plansSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
          const priceID = subscriptionData.items[0].price.id;
          const plan = plans.find(plan => plan.priceID === priceID);
          setPlanDetails(plan);
  
          if (plan && projectData.tier !== plan.name.toLowerCase()) {
            await updateDoc(projectDocRef, { 
              tier: plan.name.toLowerCase(),
              languageLimit: plan.languageLimit
            });
            setProjectData(prevData => ({...prevData, tier: plan.name.toLowerCase(), languageLimit: plan.languageLimit}));
          }
        } else {
          setSubscriptionActive(false);
          if (projectData.tier !== 'free') {
            await updateDoc(projectDocRef, { 
              tier: 'free',
              languageLimit: 1
            });
            setProjectData(prevData => ({...prevData, tier: 'free', languageLimit: 1}));
          }
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProjectData();
  }, [projectName, navigate]);

  const handleLanguagesAdjustment = () => setShowAdjustLanguagesModal(true);

  const handleSaveLanguages = async (adjustedLanguages) => {
    const currentUser = auth.currentUser;
    const projectDocRef = doc(firestore, 'customers', currentUser.uid, 'projects', projectData.id);

    try {
      await updateDoc(projectDocRef, { translatedLanguages: adjustedLanguages });
      setProjectData({ ...projectData, translatedLanguages: adjustedLanguages });
      setShowAdjustLanguagesModal(false);
    } catch (error) {
      console.error('Error updating project languages:', error);
    }
  };

  const renderDashboard = () => (
    <div className='project-dashboard'>
      <div className='dashboard-content'>
        <div className='subscription-card'>
          <h2>Subscription Details</h2>
          <div>
            <p><strong>Plan:</strong> {planDetails?.name || 'Free'}</p>
            {subscriptionActive && (
              <>
                <p><strong>Monthly Price:</strong> ${((subscriptionDetails?.items[0]?.price?.unit_amount || 0) / 100).toFixed(2)}</p>
                <p><strong>Renewal Date:</strong> {subscriptionDetails?.current_period_end ? new Date(subscriptionDetails.current_period_end.seconds * 1000).toLocaleDateString() : 'N/A'}</p>
              </>
            )}
            <p><strong>Language Limit:</strong> {planDetails?.languageLimit || 1}</p>
            {projectData.tier === 'free' && (
              <p className="free-plan-notice">
                <strong>Free Plan:</strong> Only the home page will be translated. Upgrade to translate all pages.
              </p>
            )}
            <p>
              <strong>Selected Languages:</strong>
              {projectData.translatedLanguages.map((lang, index) => (
                <React.Fragment key={lang}>
                  {index > 0 && ', '}
                  <a className='selected-langs' href={`https://${projectData.domainUrl}/${lang}`} target="_blank" rel="noopener noreferrer">
                    {lang}
                  </a>
                </React.Fragment>
              ))}
              <img
                src={editIcon}
                alt="Edit Languages"
                title="Change selected languages"
                className="edit-icon"
                onClick={handleLanguagesAdjustment}
                style={{ cursor: 'pointer', marginLeft: '10px', width: '20px', height: '20px' }}
              />
            </p>
          </div>
        </div>
        <div className='dashboard-buttons'>
          <button 
            onClick={() => projectData.tier !== 'free' && navigate(`/project/${projectName}/translate`)} 
            className={`dashboard-button ${projectData.tier === 'free' ? 'locked' : ''}`}
            disabled={projectData.tier === 'free'}
          >
            {projectData.tier === 'free' && <img src={lockIcon} alt="Locked" className="lock-icon" title="Upgrade to edit translations" />}
            Translations
            {projectData.tier === 'free' && <img src={lockIcon} alt="Locked" className="lock-icon" title="Upgrade to edit translations" />}
          </button>
          <button onClick={() => navigate(`/project/${projectName}/subscription`)} className='dashboard-button'>
            Subscription Plan
          </button>
          <button onClick={() => navigate(`/project/${projectName}/settings`)} className='dashboard-button'>
            Project Settings
          </button>
        </div>
        <AdjustLanguagesModal
          isOpen={showAdjustLanguagesModal}
          onClose={() => setShowAdjustLanguagesModal(false)}
          currentLanguages={projectData.translatedLanguages}
          maxLanguages={planDetails ? planDetails.languageLimit : 1}
          originalLanguage={projectData.originalLanguage}
          onSave={handleSaveLanguages}
        />
      </div>
    </div>
  );

  if (loading) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;
  return renderDashboard();
};

export default ProjectPage;