import React, { useState, useEffect } from 'react';
import closeIcon from '../assets/close.svg';
import './AdjustLanguagesModal.css';

const availableLanguages = [
  { name: 'English', code: 'en', flags: ['USA'] },
  { name: 'Spanish', code: 'es', flags: ['Spain'] },
  { name: 'French', code: 'fr', flags: ['France'] },
  { name: 'German', code: 'de', flags: ['Germany'] },
  { name: 'Chinese', code: 'zh', flags: ['South-Korea'] },
  { name: 'Japanese', code: 'ja', flags: ['Japan'] },
  { name: 'Portuguese', code: 'pt', flags: ['Brazil', 'Portugal'] },
  { name: 'Arabic', code: 'ar', flags: ['Morocco', 'Qatar'] }
];

const borderColors = ['#FFD650', '#FF7A79', '#4FFF8A', '#4B9FFF', '#B63ACE'];

const AdjustLanguagesModal = ({ isOpen, onClose, currentLanguages, maxLanguages, originalLanguage, onSave }) => {
  const [selectedLanguages, setSelectedLanguages] = useState(currentLanguages);
  const [languageColors, setLanguageColors] = useState({});

  useEffect(() => {
    setSelectedLanguages(currentLanguages);
    assignColorsToLanguages(currentLanguages);
  }, [currentLanguages]);

  const assignColorsToLanguages = (languages) => {
    const colors = [...borderColors];
    const assignedColors = {};
    languages.forEach(lang => {
      const randomIndex = Math.floor(Math.random() * colors.length);
      assignedColors[lang] = colors.splice(randomIndex, 1)[0];
    });
    setLanguageColors(assignedColors);
  };

  const toggleLanguage = (languageCode) => {
    let updatedLanguages;
    if (selectedLanguages.includes(languageCode)) {
      updatedLanguages = selectedLanguages.filter(lang => lang !== languageCode);
    } else if (selectedLanguages.length < maxLanguages) {
      updatedLanguages = [...selectedLanguages, languageCode];
    }
    setSelectedLanguages(updatedLanguages);
    assignColorsToLanguages(updatedLanguages);
  };

  const handleSave = () => {
    if (selectedLanguages.length === maxLanguages) {
      onSave(selectedLanguages);
      onClose();
    }
  };

  if (!isOpen) return null;

  const filteredLanguages = availableLanguages.filter(language => language.name !== originalLanguage);

  return (
    <div className="language-modal-overlay">
      <div className="language-modal-container">
        <img
          src={closeIcon}
          alt="Close"
          className="language-close-icon"
          onClick={onClose}
        />
        <h2 className="language-modal-title">Select Languages</h2>
        <p className="language-modal-description">Choose {maxLanguages} languages for translation:</p>
        <div className="language-grid">
          {filteredLanguages.map(language => (
            <div
              key={language.code}
              className={`language-item ${selectedLanguages.includes(language.code) ? 'language-selected' : ''}`}
              onClick={() => toggleLanguage(language.code)}
              style={{
                borderColor: selectedLanguages.includes(language.code) ? languageColors[language.code] : 'white'
              }}
            >
              <div className="language-flag-container">
                {language.flags.map((flag, index) => (
                  <img 
                    key={flag}
                    src={require(`../assets/flags/${flag}.png`)}
                    alt={`${language.name} flag`}
                    className={`language-flag ${index > 0 ? 'language-flag-overlap' : ''}`}
                  />
                ))}
              </div>
              <span className="language-name">{language.name}</span>
            </div>
          ))}
        </div>
        <p className="language-count">Selected: {selectedLanguages.length} / {maxLanguages}</p>
        <button 
          onClick={handleSave} 
          disabled={selectedLanguages.length !== maxLanguages}
          className="language-save-button"
        >
          Save Changes
        </button>
        <p className="warning-message">
            Any saved changes to your languages will delete your saved translations.
      </p>
      </div>
    </div>
  );
};

export default AdjustLanguagesModal;
