import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function CreateProject({ onNext, onProjectDataChange, projectName, projectId }) {
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [projectCount, setProjectCount] = useState(0);
  const [hasUnlimitedProjects, setHasUnlimitedProjects] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        const isGodModeUser = user.uid === 'GiJsFoXbSher6mcXH3UisPfWjOG2';
        setHasUnlimitedProjects(isGodModeUser);
        getProjectCount(user.uid);
      } else {
        setError('Please log in to continue.');
      }
    });
    return unsubscribe;
  }, []);

  const getProjectCount = async (userId) => {
    const projectsRef = collection(firestore, 'customers', userId, 'projects');
    const projectsSnapshot = await getDocs(projectsRef);
    setProjectCount(projectsSnapshot.size);
  };

  const handleChangeProjectName = (e) => {
    const newName = e.target.value;
    onProjectDataChange({ projectName: newName });
  };

  const checkDuplicateProjectName = async (name) => {
    if (currentUser) {
      const q = query(collection(firestore, 'customers', currentUser.uid, 'projects'), where('projectName', '==', name));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!hasUnlimitedProjects && projectCount >= 3) {
      setError('You have reached the maximum limit of 3 projects.');
      return;
    }

    if (currentUser) {
      const isDuplicate = await checkDuplicateProjectName(projectName);
      if (isDuplicate) {
        setError('Project name already exists. Please choose another name.');
      } else {
        const projectData = {
          projectName,
          host: 'webflow',
          projectLocked: false
        };
        
        onNext(projectData);
      }
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <h2 className="form-title">Let's Spread Some Language Love</h2>
        {hasUnlimitedProjects && (
          <p className="god-mode-message" style={{ color: '#4CAF50', fontWeight: 'bold' }}>
            Welcome to God Mode
          </p>
        )}
        <p className="form-description">Your Webflow site is about to speak to the world. Let's get started!</p>
        <div className="webflow-message">
          <p>This service is optimized for <a href="https://webflow.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}>Webflow</a> users. Ensure your project is hosted on Webflow for working experience.</p>
        </div>
        <div className="form-group">
          <label htmlFor="projectName" className="form-label">Project Name:</label>
          <input
            id="projectName"
            type="text"
            value={projectName}
            className="form-input"
            onChange={handleChangeProjectName}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <p className="project-count">
          Projects: {projectCount}{hasUnlimitedProjects ? ' (Unlimited)' : '/3'}
        </p>
        <button 
          type="submit" 
          className="form-button" 
          disabled={!!error || (!hasUnlimitedProjects && projectCount >= 3)}
        >
          {!hasUnlimitedProjects && projectCount >= 3 ? 'Project Limit Reached' : 'Next'}
        </button>
      </form>
    </div>
  );
}

export default CreateProject;