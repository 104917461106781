import React from 'react';
import './LoadingScreen.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="contain">
        {[...Array(5)].map((_, index) => (
          <svg key={index}>
            <circle cx="66" cy="66" fill="none" r="60" stroke="ghostwhite" strokeDasharray="190" strokeDashoffset="190" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6" />
          </svg>
        ))}
      </div>
    </div>
  );
};

export default Loader;