// Dashboard.js
import React, { useState, useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import SideNav from '../components/nav/SideNav';
import DashboardHeader from '../components/nav/DashboardHeader';
import Loader from '../components/LoadingScreen';
import './Dashboard.css';

const Dashboard = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    let title = 'Dashboard'; // Default title

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000); // Adjust this time as needed

        return () => clearTimeout(timer);
    }, [location.pathname]);

    switch (location.pathname) {
        case '/':
            title = 'App Name';
            break;
        case '/projects':
            title = 'Projects';
            break;
        case '/projects/setup':
            title = 'Add New Project';
            break;
        case '/settings':
            title = 'Settings';
            break;
        default:
            title = location.pathname.substring(1).charAt(0).toUpperCase() + location.pathname.slice(2);
    }

    return (
        <div className="dashboard">
            <SideNav />
            <div className="dashboard-main">
                <DashboardHeader title={title} />
                <div className="dashboard-content">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Outlet />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
